.links-page {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-button {
  cursor: pointer;
  font-family: sans-serif;
  font-size: 20px;
  display: inline-flex;
  justify-content: center;
  gap: 1rem;
  padding: 10px 15px;
  color: white;
  border: 1px solid #001858;
  background-color: #001858;
  border-radius: 10px;
}
.download-button > a {
  color: inherit;
}
.download-button:hover {
  color: #001858;
}
.download-button:hover {
  color: #001858;
  border: 1px solid white;
  background-color: white;
}
