.App {
  width: 100%;
  height: 100vh;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

@media screen and (min-width: 768px) {
  .App {
    padding: 35px 100px;
  }
}

.App-contentPlaceholder {
  height: 200px;
  width: 100%;
  max-width: 900px;
  background-color: #ccc;
  border-radius: 8px;
}
