@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-width: 320px;
  background-color: #fef6e4;
}
html {
  width: 100%;
  height: 100%;
}
