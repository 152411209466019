.navbar-wrapper {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  color: #001858;
  font-family: sans-serif;
  font-size: 20px;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}
.navbar {
  display: inline-flex;
  gap: 20px;
}
.navbar > a {
  text-decoration: none;
  color: #001858;
}
